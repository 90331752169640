<template>
  <div>
    <div class="content">
      <div class="all">
        <table id="table1">
          <tr>
            <td class="title7">
              <el-checkbox></el-checkbox> <span>全选</span>
            </td>
            <td class="title2">商品信息</td>
            <td class="title3">单价</td>
            <td class="title4">数量/租期</td>
            <td class="title5">小计</td>
            <td class="title6">操作</td>
          </tr>
        </table>
        <!-- 购物车有东西 -->
        <div class="visible" v-if="hidden">
          <!-- 购物车 -->
          <div class="carlistAll">
            <div class="listTitle">
              <el-row>
                <el-col :span="14">
                  <div class="allChecked">
                    <el-checkbox></el-checkbox>
                    <span class="storeName">店铺：汉威广园</span>
                  </div>
                </el-col>
                <el-col :span="10">
                  <span id="contatcUs">和我联系</span>
                </el-col>
              </el-row>
            </div>
            <div class="list">
              <div class="listBlock">
                <div class="column1">
                  <div style="margin: 0px 15px">
                    <el-checkbox></el-checkbox>
                  </div>
                  <div class="listImg">
                    <img src="../img/listimg.jpg" />
                  </div>
                  <div style="width: 100%; padding: 0 10px">
                    <div class="brandTop">油墨轴承</div>
                    <div class="brandbottom">
                      <el-row>
                        <el-col :span="19"> 品牌:EBC 型号:TRA-6681</el-col>
                        <el-col :span="5"
                          ><span @click="change()">修改</span></el-col
                        >
                      </el-row>
                    </div>
                  </div>
                </div>
              </div>
              <div class="listblockPrice">
                <div class="parice1">$2469</div>
                <div class="parice2">$2469</div>
              </div>
              <div class="listblockNum">
                <el-input-number
                  v-model="num"
                  @change="handleChange"
                  :min="1"
                  :max="10"
                ></el-input-number>
              </div>
              <div class="listblockCount">
                <span>$2469</span>
              </div>
              <div class="listblockoperation">
                <div class="operation1">移入收藏夹</div>
                <div class="operation2">删除</div>
              </div>
            </div>
            <div class="list">
              <div class="listBlock">
                <div class="column1">
                  <div style="margin: 0px 15px">
                    <el-checkbox></el-checkbox>
                  </div>
                  <div class="listImg">
                    <img src="../img/listimg.jpg" />
                  </div>
                  <div style="width: 100%; padding: 0 10px">
                    <div class="brandTop">油墨轴承</div>
                    <div class="brandbottom">
                      <el-row>
                        <el-col :span="19"> 品牌:EBC 型号:TRA-6681</el-col>
                        <el-col :span="5"
                          ><span @click="change()">修改</span></el-col
                        >
                      </el-row>
                    </div>
                  </div>
                </div>
              </div>
              <div class="listblockPrice">
                <div class="parice1">$2469</div>
                <div class="parice2">$2469</div>
              </div>
              <div class="listblockNum">
                <el-input-number
                  v-model="num"
                  @change="handleChange"
                  :min="1"
                  :max="10"
                ></el-input-number>
              </div>
              <div class="listblockCount">
                <span>$2469</span>
              </div>
              <div class="listblockoperation">
                <div class="operation1">移入收藏夹</div>
                <div class="operation2">删除</div>
              </div>
            </div>
            <div class="list">
              <div class="listBlock">
                <div class="column1">
                  <div style="margin: 0px 15px">
                    <el-checkbox></el-checkbox>
                  </div>
                  <div class="listImg">
                    <img src="../img/listimg.jpg" />
                  </div>
                  <div style="width: 100%; padding: 0 10px">
                    <div class="brandTop">油墨轴承</div>
                    <div class="brandbottom">
                      <el-row>
                        <el-col :span="19"> 品牌:EBC 型号:TRA-6681</el-col>
                        <el-col :span="5"
                          ><span @click="change()">修改</span></el-col
                        >
                      </el-row>
                    </div>
                  </div>
                </div>
              </div>
              <div class="listblockPrice">
                <div class="parice1">$2469</div>
                <div class="parice2">$2469</div>
              </div>
              <div class="listblockNum">
                <el-input-number
                  v-model="num"
                  @change="handleChange"
                  :min="1"
                  :max="10"
                ></el-input-number>
              </div>
              <div class="listblockCount">
                <span>$2469</span>
              </div>
              <div class="listblockoperation">
                <div class="operation1">移入收藏夹</div>
                <div class="operation2">删除</div>
              </div>
            </div>
          </div>
          <!-- 失效商品 -->
          <table id="table2">
            <tr>
              <td class="title1">失效商品</td>
              <td class="title2">商品信息</td>
              <td class="title3">单价</td>
              <td class="title4">数量/租期</td>
              <td class="title5">小计</td>
              <td class="title6">操作</td>
            </tr>
          </table>
          <div class="carlistAll">
            <div class="listTitle">
              <el-row>
                <el-col :span="14">
                  <div class="allChecked">
                    <!-- <el-checkbox></el-checkbox> -->
                    <span class="storeName">店铺：汉威广园</span>
                  </div>
                </el-col>
                <el-col :span="10">
                  <span id="contatcUs">和我联系</span>
                </el-col>
              </el-row>
            </div>
            <div class="list">
              <div class="listBlock">
                <div class="column1">
                  <div style="margin: 0px 15px">
                    <!-- <el-checkbox></el-checkbox> -->
                  </div>
                  <div class="listImg">
                    <img src="../img/listimg.jpg" />
                  </div>
                  <div style="width: 100%; padding: 0 10px">
                    <div class="brandTop">油墨轴承</div>
                    <div class="brandbottom">
                      <el-row>
                        <el-col :span="19"> 品牌:EBC 型号:TRA-6681</el-col>
                        <el-col :span="5"
                          ><span @click="change()">失效</span></el-col
                        >
                      </el-row>
                    </div>
                  </div>
                </div>
              </div>
              <div class="listblockPrice">
                <div class="parice1">$2469</div>
                <div class="parice2">$2469</div>
              </div>
              <div class="listblockNum2">
                <!-- <el-input-number
                v-model="num"
                @change="handleChange"
                :min="1"
                :max="10"
              ></el-input-number> -->
                <div>日期範圍</div>
                <el-date-picker
                  v-model="value1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
              <div class="listblockCount">
                <span>$2469</span>
              </div>
              <div class="listblockoperation">
                <div class="operation1">移入收藏夹</div>
                <div class="operation2">删除</div>
              </div>
            </div>
          </div>
          <div class="carlistAll">
            <div class="listTitle">
              <el-row>
                <el-col :span="14">
                  <div class="allChecked">
                    <!-- <el-checkbox></el-checkbox> -->
                    <span class="storeName">店铺：汉威广园</span>
                  </div>
                </el-col>
                <el-col :span="10">
                  <span id="contatcUs">和我联系</span>
                </el-col>
              </el-row>
            </div>
            <div class="list">
              <div class="listBlock">
                <div class="column1">
                  <div style="margin: 0px 15px">
                    <!-- <el-checkbox></el-checkbox> -->
                  </div>
                  <div class="listImg">
                    <img src="../img/listimg.jpg" />
                  </div>
                  <div style="width: 100%; padding: 0 10px">
                    <div class="brandTop">油墨轴承</div>
                    <div class="brandbottom">
                      <el-row>
                        <el-col :span="19"> 品牌:EBC 型号:TRA-6681</el-col>
                        <el-col :span="5"
                          ><span @click="change()">失效</span></el-col
                        >
                      </el-row>
                    </div>
                  </div>
                </div>
              </div>
              <div class="listblockPrice">
                <div class="parice1">$2469</div>
                <div class="parice2">$2469</div>
              </div>
              <div class="listblockNum2">
                <!-- <el-input-number
                v-model="num"
                @change="handleChange"
                :min="1"
                :max="10"
              ></el-input-number> -->
                <div>日期範圍</div>
                <el-date-picker
                  v-model="value1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
              <div class="listblockCount">
                <span>$2469</span>
              </div>
              <div class="listblockoperation">
                <div class="operation1">移入收藏夹</div>
                <div class="operation2">删除</div>
              </div>
            </div>
          </div>
          <!-- 提交訂單 -->
          <div class="order">
            <table>
              <tr>
                <div class="account">
                  <div>
                    <el-checkbox class="accountCheckbox"></el-checkbox>
                    <span>全选</span>
                  </div>
                  <div>删除所选商品</div>
                  <div>收入到收藏夹</div>
                </div>
                <td>
                  共<span class="price">26</span>件商品,已选择<span
                    class="price"
                    >5</span
                  >件
                </td>
                <td><span>商品合计:</span></td>
                <td><span class="price2 price">¥2686.00</span></td>
                <td class="orderBtn">
                  <el-button type="primary">提交訂單</el-button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- 空购物车 -->
        <div class="hidden " v-else>
            <img src="../img/shopCar.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        hidden:true,
      value1: "",
      num: 1,
      checked: true,
    };
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    change() {},
  },
};
</script>

<style lang='scss' scoped>
body {
  background-color: #f5f5f5;
  .content {
    width: 1440px;
    margin: 0 auto;
    background-color: #f5f5f5;
    .all {
      width: 1410px;
      margin: 0 auto;
      letter-spacing: 1px;
      .carlistAll {
        margin-top: 25px;
        .listTitle {
          width: 300px;
          margin: 30px 0 15px 0;
          #contatcUs {
            font-size: 16px;
            // font-family: PingFangSC-Regular, PingFang SC;
            color: #4a99f6;
          }
        }
        .list {
          width: 1410px;
          background-color: white;
          display: flex;
          border-top: 1px solid #dddddd;
          border-left: 1px solid #dddddd;
          border-right: 1px solid #dddddd;
          &:last-of-type {
            border-bottom: 1px solid #dddddd;
          }
          .listBlock {
            width: 560px;
            padding: 24px 0;
            border-right: 1px solid #dddddd;
            .column1 {
              display: flex;
              .listImg {
                img {
                  width: 100px;
                  height: 100px;
                  border-radius: 8px;
                }
              }
              .brandTop {
                font-size: 16px;
                // font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
                color: #000000;
                line-height: 22px;
              }
              .brandbottom {
                margin-top: 20px;
                font-size: 14px;
                // font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 20px;
                span {
                  color: #4a99f6;
                  cursor: pointer;
                }
              }
            }
          }
          .listblockPrice {
            width: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #dddddd;
            //   border: 1px solid #dddddd;
            .parice1 {
              margin-bottom: 15px;
            }
            .parice2 {
              font-size: 16px;
              font-weight: bold;
              color: #000000;
            }
          }
          .listblockNum {
            width: 363px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #dddddd;
            //   border: 1px solid #dddddd;
          }
          .listblockNum2 {
            width: 363px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #dddddd;
            //   border: 1px solid #dddddd;
          }
          .listblockCount {
            width: 100px;
            display: flex;
            color: #4a99f6;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #dddddd;
            //   border: 1px solid #dddddd;
          }
          .listblockoperation {
            width: 280px;
            //   border: 1px solid #dddddd;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #595959;
            .operation1 {
              margin-bottom: 20px;
            }
          }
        }
      }
      table {
        width: 1410px;
        height: 55px;
        margin-top: 25px;
        text-align: center;
        // background-color: #e6e6e6;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 20px;
        .title1 {
          padding-left: 20px;
          text-align: left;
          width: 260px;
        }
        .title2 {
          width: 298px;
        }
        .title3 {
          width: 99px;
        }
        .title4 {
          width: 360px;
        }
        .title5 {
          width: 100px;
        }
        .title6 {
          // width: 280px;
        }
        .title7 {
          padding-left: 10px;
          text-align: left;
          width: 260px;
        }
      }
      #table1 {
        background-color: #e6f7ff;
      }
      #table2 {
        background-color: #e6e6e6;
      }
      //   提交訂單
      .order {
        padding: 20px 0;
        table {
          width: 1410px;
          height: 55px;
          margin-top: 25px;
          text-align: center;
          background-color: #e6f7ff;
          font-size: 14px;
          font-weight: normal;
          color: #333333;
          tr {
            td {
              .el-button {
                height: 100%;
                width: 190px;
              }
            }
            .account {
              display: flex;
              height: 80px;
              line-height: 80px;
              justify-content: space-between;
              width: 560px;
              .accountCheckbox {
                padding-left: 20px;
              }
            }
          }
        }
        .price {
          font-size: 16px;
          font-weight: bolder;
          color: #4a99f6;
          padding: 0 5px;
        }
        .price2 {
          font-size: 28px;
        }
        .orderBtn {
          text-align: right;
        }
      }
    }
  }
}
.storeName {
  color: black;
  font-size: 16px;
  padding-left: 10px;
  font-weight: bold;
}
.hidden{
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 120px;
        height: 120px;
    }
}
</style>